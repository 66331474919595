import { ThemeConfig } from './types';

export const h5: ThemeConfig = {
    tenant: 'h5',
    baseColor: '#245A80',
    navColor: '#fff',
    textColor: '#444',
    hoverColor: '#E08BA8',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#E08BA8',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h5;
