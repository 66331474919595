import { ThemeConfig } from './types';

export const h6: ThemeConfig = {
    tenant: 'h6',
    baseColor: '#4d90cd',
    navColor: '#fff',
    textColor: '#444',
    hoverColor: '#4d90cd',
    disabled: '#999999',
    inputBgColor: "#FBFBFC",
    matrixHoverColor: '#4d90cd',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#FBFBFC',
    matrixOddTextColor: '#444'
};

export default h6;

/*
@kleur_lichtgrijs: #b3b3b3;
@kleur_donkergrijs: #444;
@text_header: #2d2d2d;
@text_link: #4d90cd;
@text_link_response: #e9f0f9;
*/